(function ($) {
    $.fn.goTo = function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top + 'px'
        }, 'fast');
        return this; // for chaining...
    }
})(jQuery);

new WOW().init();

// Helper function for add element box list in WOW
WOW.prototype.addBox = function (element) {
    this.boxes.push(element);
};
// Init WOW.js and get instance
var wow = new WOW();
wow.init();
// Attach scrollSpy to .wow elements for detect view exit events,
// then reset elements and add again for animation
$('.wow').on('scrollSpy:exit', function () {
    $(this).css({
        'visibility': 'hidden',
        'animation-name': 'none'
    }).removeClass('animated');
    wow.addBox(this);
}).scrollSpy();

$(".middle_section").click(function () {
    var siguiente = $(this).closest('section').next();
    scroll(siguiente);
});

$(".nav_right").click(function () {
    $("#menu").css("transform", "translate(0)");
});
$("#close").click(function () {
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});

$(".navbar-brand").click(function () {
    $('.navbar-toggler').click(); //bootstrap 4.x
    var box = $(this).data("section");
    console.log(box);
    if (box.includes("index.html")){
        window.location.replace(box);
    }
    scroll($(box));
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});



$(".link_section").click(function () {
    var box = $(this).data("section");
    console.log(box);
    if (box.includes("index.html")){
        window.location.replace(box);
    }
    scroll($(box));

});

$(".nav-link").click(function () {
    $('.navbar-toggler').click(); //bootstrap 4.x
    var box = $(this).data("section");
    console.log(box);
    if (box.includes("index.html")){
        window.location.replace(box);
    }
    scroll($(box));
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});


$(document).on('click', 'a[href^="#"]', function(e) {
    // target element id
    var id = $(this).attr('href');

    // target element
    var $id = $(id);
    if ($id.length === 0) {
        return;
    }

    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();

    // top position relative to the document
    var pos = $id.offset().top;

    // animated top scrolling
    $('body, html').animate({scrollTop: pos- 100});
});

function scroll(element){
    var pos = element.offset().top;
    $('body, html').animate({scrollTop: pos - 100});
}



var textos_nacional_counter  = 0;
var textos_extranjero_counter = 0;

$(".extranjero_right").click(function(event) {
    event.preventDefault();
    textos_extranjero_counter = textos_extranjero_counter + 1;
    if (textos_extranjero_counter === 3){
        textos_extranjero_counter = 0;
    }
    $("#extranjero_texto").text(textos_extranjero[textos_extranjero_counter]);
});


$(".extranjero_left").click(function(event) {
    event.preventDefault();
    textos_extranjero_counter = textos_extranjero_counter -1;
    if (textos_extranjero_counter === -1){
        textos_extranjero_counter = 2;
    }
    $("#extranjero_texto").text(textos_extranjero[textos_extranjero_counter]);
});


$(".nacional_right").click(function(event) {
    event.preventDefault();
    textos_nacional_counter = textos_nacional_counter + 1;
    if (textos_nacional_counter === 3){
        textos_nacional_counter = 0;
    }
    $("#nacional_texto").text(textos_nacional[textos_nacional_counter]);
});


$(".nacional_left").click(function(event) {
    event.preventDefault();
    textos_nacional_counter = textos_nacional_counter -1;
    if (textos_nacional_counter === -1){
        textos_nacional_counter = 2;
    }
    $("#nacional_texto").text(textos_nacional[textos_nacional_counter]);
});


function success(data){
    iziToast.success({
        id: 'success',
        title: 'Exito',
        message: mensaje_exito,
        position: 'bottomRight',
        transitionIn: 'fadeInDown'
    });
}

$("#enviar_contact").click(function(event) {
    event.preventDefault();
    if (($("#name").val() == "") | ($("#email").val() == "") |  ($("#message").val() == "")){
        iziToast.error({
            id: 'error',
            title: 'Error',
            message: error_fields,
            position: 'bottomRight',
            transitionIn: 'fadeInDown'
        });
        return null 
    }
    
    var data = {"name": $("#name").val(),
                "email": $("#email").val(),
                "message": $("#message").val(),
                "phone": $("#phone").val()
                }
    $.ajax({
        type: "POST",
        url: "send.php",
        data: data, 
        success: success
      }).fail(function (returned_data){
        iziToast.error({
            id: 'error',
            title: 'Error',
            message: 'Ocurrio un error con el servidor.',
            position: 'bottomRight',
            transitionIn: 'fadeInDown'
        });
      });
});